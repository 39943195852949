import React, { useEffect, useRef, useState } from "react";
import { Handle, useReactFlow } from "reactflow";
import { useNavigate } from "react-router-dom";

const CustomeNode = ({ id, data }) => {
  const nodeRef = useRef(null);
  const [showToolbar, setShowToolbar] = useState(false);
  const { setNodes } = useReactFlow();
  const navigate = useNavigate();
  let lastTap = 0;

  useEffect(() => {
    const handleDoubleTap = (event) => {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - lastTap;
      if (tapLength < 500 && tapLength > 0) {
        event.preventDefault();
        const doubleTapEvent = new MouseEvent("dblclick", {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        nodeRef.current.dispatchEvent(doubleTapEvent);
      }
      lastTap = currentTime;
    };

    const nodeElement = nodeRef.current;
    nodeElement.addEventListener("touchend", handleDoubleTap);

    return () => {
      nodeElement.removeEventListener("touchend", handleDoubleTap);
    };
  }, []);

  const handleClick = () => {
    setShowToolbar((prev) => !prev);
  };

  const handleDelete = () => {
    setNodes((nds) => nds.filter((node) => node.id !== id));
  };

  const handleDetails = () => {
    navigate(`/checklist/${id}`, {
      state: { fromGoalMaps: true },
    });
  };

  // Parse label expecting format: Name(completed/total) or Achieve Goal (percent% completed) (completed/total)
  let name = data.label;
  let completed = 0,
    total = 0,
    percent = 0;
  const regex = /(.*)\((\d+)\/(\d+)\)$/;
  const overallProgressRegex =
    /Achieve Goal \((\d+)% completed\)\s(\d+)\/(\d+)$/;
  const match = regex.exec(data.label);
  const overallMatch = overallProgressRegex.exec(data.label);

  if (match) {
    name = match[1].trim();
    completed = Number(match[2]);
    total = Number(match[3]);
    percent = total > 0 ? Math.round((completed / total) * 100) : 0;
  }
  // else if (overallMatch) {
  //     name = "Achieve Goal";

  //     percent = Number(overallMatch[1]);
  //     completed = Number(overallMatch[2]);
  //     total = Number(overallMatch[3]);
  //     console.log("overallMatch", percent);
  //   }

  return (
    <div
      ref={nodeRef}
      onClick={handleClick}
      className={`${
        name.includes("Achieve")
          ? "text-green-900 font-semibold rounded-xl"
          : "text-gray-600"
      } border border-gray-500 rounded p-2  w-40 relative`}
    >
      {/* Input Handle */}
      <Handle type="target" position="top" className="bg-gray-700" />
      {/* Node Header */}
      <div className="mb-1 text-center">
        {name} {total > 0 && ` ${completed}/${total}`}
      </div>
      {/* Node Main Area with Progress Bar */}
      <div
        className={`${
          name.includes("Achieve")
            ? "hidden"
            : "bg-gray-200 rounded h-2 relative"
        }`}
      >
        <div
          className={`${
            name.includes("Achieve") ? "hidden" : "bg-green-500 h-full rounded"
          }`}
          style={{ width: `${percent}%` }}
        ></div>
      </div>
      <div className="text-xs text-center mt-1">
        {name.includes("Achieve") ? "" : `${percent} % completed`}
      </div>
      {/* Output Handle */}
      <Handle
        type="source"
        position={name.includes("Achieve") ? "top" : "bottom"}
        className="bg-purple-500"
      />
      {/* Toolbar */}
      {showToolbar && (
        <div className="flex gap-2 absolute top-0 right-0 bg-white border border-gray-500 rounded p-1 z-10">
          <button
            className="border p-1 text-xs font-medium rounded-lg bg-gray-100"
            onClick={handleDelete}
          >
            Delete
          </button>
          <button
            className="border p-1 text-xs font-medium rounded-lg bg-gray-100"
            onClick={handleDetails}
          >
            Details
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomeNode;
