import React, { useState } from "react";
import {
  AiOutlineEdit,
  AiFillCheckCircle,
  AiFillDelete,
  AiFillCloseCircle,
  AiOutlineReload,
  AiOutlinePlus,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  createANewCheckList,
  deleteACheckList,
} from "../features/checklist/checkListSlice";
import {
  getItemsByCheckListID,
  addNewChecklistItemLocally,
} from "../features/checklistItem/checkListItemSlice";
import PopupComponent from "../components/PopupComponent";

const textareaStyle = {
  width: "100%", // Set the desired width here
  height: "100px", // Set the desired height here
};

function CheckListTitle({ checklist }) {
  const [Item, setItem] = useState(checklist);
  //console.log(Item)

  const [isEditing, setIsEditing] = useState(Item.isEditing);
  const [isCategoryPopupOpen, setIsCategoryPopupOpen] = useState(false); // State for category popup
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { categories } = useSelector((state) => state.categories);

  const [checked, setChecked] = useState(
    Item.status === "completed" ? true : false
  );

  const dispatch = useDispatch();

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setIsCategoryPopupOpen(false);
    dispatch(
      addNewChecklistItemLocally({
        description: "",
        status: "pending",
        checkListID: Item._id,
        isEditing: true,
        updatedAt: new Date(),
        category: category,
      })
    );
  };

  const handleAddCheckListItem = () => {
    if (checklist.category.name === "general") setIsCategoryPopupOpen(true);
    else {
      dispatch(
        addNewChecklistItemLocally({
          description: "",
          status: "pending",
          checkListID: Item._id,
          isEditing: true,
          updatedAt: new Date(),
          category: Item.category,
        })
      );
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCloseClick = () => {
    setIsEditing(false);
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;

    setChecked(isChecked);
    if (isChecked) setIsEditing(true);
    else setIsEditing(false);
  };

  const handleSaveClick = () => {
    dispatch(
      createANewCheckList({
        checkListID: Item._id,
        name: Item.name,
        status: checked ? "completed" : "pending",
        category: Item.category._id,
      })
    )
      .unwrap()
      .then(() => {
        // We got a good response so navigate the user
        //   navigate('/checklist')
        toast.success("saved");
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong.");
      });
    setIsEditing(false);
    //onSave(text);
  };
  const handleRefreshClick = () => {
    dispatch(getItemsByCheckListID(Item._id))
      .unwrap()
      .then(() => {
        // We got a good response so navigate the user
        //   navigate('/checklist')
        toast.success("downloaded");
      })
      .catch(toast.error);
    setIsEditing(false);
    //onSave(text);
  };
  const handleDeleteClick = () => {
    dispatch(deleteACheckList({ checkListID: Item._id }))
      .unwrap()
      .then(() => {
        // We got a good response so navigate the user
        //   navigate('/checklist')
        //  updatedTextBoxes.splice(index, 1)
        // setTextBoxes(updatedTextBoxes);
        console.log("deleeee");
        toast.success("deleted");
      })
      .catch(toast.error);
    setIsEditing(false);
    // onDelete();
  };

  const handleChange = (event) => {
    // Limit text to 100 characters per line
    const newText = event.target.value
      .split("\n")
      .map((line) => line.slice(0, 100))
      .join("\n");
    // console.log(Item.checkListID)
    setItem({ ...Item, name: newText });
  };

  // return (
  //     <div>
  //         {JSON.stringify(Item)}
  //     </div>
  // )
  return !Item ? (
    <div>
      <h1>No items yet...</h1>
    </div>
  ) : (
    <div className="sticky top-32 md:top-14 bg-white z-10  ">
      <div className="mt-10 text-2xl font-extrabold text-gray-500 text-center">
        <div className={`box ${isEditing ? "editing-mode" : "view-mode"}`}>
          {isEditing ? (
            <div className="editing-content ">
              <textarea
                rows="10"
                cols="400"
                value={Item.name}
                onChange={handleChange}
                maxLength={800} // Set an overall character limit (e.g., 4 lines of 100 characters each)
                // style={textareaStyle}
                className="w-full h-24 text-gray-600 border"
              />

              <button onClick={handleSaveClick} className=" text-emerald-400">
                <AiFillCheckCircle />
              </button>
              <button
                onClick={handleCloseClick}
                className=" text-red opacity-60"
              >
                <AiFillCloseCircle />
              </button>
            </div>
          ) : (
            <div className="view-content ">
              <div className={`bold-font ${checked ? "completed" : ""}`}>
                {Item.name}{" "}
                <span className="text-sm text-gray-400">
                  ({checklist.category.name})
                </span>
              </div>
              <button
                onClick={handleEditClick}
                className="px-4 py-1 m-2 w-10 h-10 hover:opacity-20"
              >
                <AiOutlineEdit />
              </button>
              <button
                onClick={handleDeleteClick}
                className="px-4 py-1 m-2 w-10 h-10 hover:opacity-20"
              >
                <AiFillDelete />
              </button>

              <button
                onClick={handleRefreshClick}
                className="px-4 py-1 m-2 w-10 h-10 hover:opacity-20"
              >
                <AiOutlineReload />
              </button>

              <button
                className="px-4 py-1 m-2 w-10 h-10 hover:opacity-20"
                onClick={handleAddCheckListItem}
              >
                <AiOutlinePlus />
              </button>
              <PopupComponent
                isOpen={isCategoryPopupOpen}
                onClose={() => setIsCategoryPopupOpen(false)}
              >
                <h2 className="flex items-center justify-center text-xl font-bold">
                  Select Item Category
                </h2>
                <div className="flex flex-wrap items-center justify-center gap-2">
                  {categories.map((category) => (
                    <button
                      key={category._id}
                      onClick={() => handleCategorySelect(category)}
                      className="bg-blue-500 text-white px-4 py-2 rounded m-2"
                    >
                      {category.name}
                    </button>
                  ))}
                </div>
              </PopupComponent>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CheckListTitle;
