import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Set up headers with token
const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

// Create a new Goal Map
const createGoalMap = async (goalMapData, token) => {
  const response = await axios.post(
    `${API_URL}/goal-map`,
    goalMapData,
    getConfig(token)
  );
  return response.data;
};

// Fetch all Goal Maps for the logged-in user
const fetchGoalMaps = async (token) => {
  const response = await axios.get(
    `${API_URL}/goal-maps`,
    getConfig(token)
  );
  return response.data;
};

// Fetch a single Goal Map by ID
const fetchGoalMapById = async (goalMapId, token) => {
  const response = await axios.get(
    `${API_URL}/goal-maps/${goalMapId}`,
    getConfig(token)
  );
  return response.data;
};

// Update a Goal Map
const updateGoalMap = async (goalMapId, goalMapData, token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
  const response = await axios.post(
    `${API_URL}/goal-map/${goalMapId}`,
    goalMapData,
    config
  );
  return response.data;
};

// Delete a Goal Map
const deleteGoalMap = async (goalMapId, token) => {
  const response = await axios.delete(
    `${API_URL}/goal-map/${goalMapId}`,
    getConfig(token)
  );
  return response.data;
};

const goalMapService = {
  createGoalMap,
  fetchGoalMaps,
  fetchGoalMapById,
  updateGoalMap,
  deleteGoalMap,
};

export default goalMapService;
