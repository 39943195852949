import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGoalMaps, deleteGoalMap } from "../features/goalmap/goalMapSlice";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import PopupComponent from "../components/PopupComponent";

const GoalMapList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { goalMaps, isLoading } = useSelector((state) => state.goalMaps);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    dispatch(fetchGoalMaps());
  }, [dispatch]);

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setIsPopupOpen(true);
  };

  const confirmDelete = () => {
    if (deleteId) {
      dispatch(deleteGoalMap(deleteId))
        .unwrap()
        .then((updatedItem) => {
          toast.success("Goal Map deleted successfully!");
        })
        .catch((error) => {
          toast.error(error.message || "Something went wrong!", {
            autoClose: 2000,
          });
        });
      setIsPopupOpen(false);
      setDeleteId(null);
    }
  };

  const cancelDelete = () => {
    setIsPopupOpen(false);
    setDeleteId(null);
  };

  return (
    <div className="p-6 m-2 bg-white rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Goal Maps</h1>
      <button
        onClick={() => navigate("/goal-map/create")}
        className="bg-purple-500 text-white px-4 py-2 rounded mb-6 hover:bg-blue-700 transition duration-300"
      >
        Create Goal Map
      </button>

      {isLoading ? (
        <p className="text-gray-600">Loading...</p>
      ) : goalMaps.length > 0 ? (
        <ul className="space-y-4">
          {goalMaps.map((goal) => (
            <li
              key={goal._id}
              className="flex justify-between items-center p-4 border rounded-lg shadow-sm bg-gray-50 hover:bg-gray-100 transition duration-300"
            >
              <p
                className="w-3/4 p-2 text-gray-700 cursor-pointer"
                onClick={() => navigate(`/goal-map/${goal._id}`)}
              >
                {goal.name}
              </p>
              <FaTrash
                className="text-red-500 cursor-pointer hover:text-red-700 transition duration-300"
                onClick={() => handleDeleteClick(goal._id)}
              />
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-600">No goal maps available.</p>
      )}
      <PopupComponent isOpen={isPopupOpen} onClose={cancelDelete}>
        <h2 className="flex items-center justify-center text-xl font-bold text-gray-600">
          Confirm Deletion
        </h2>
        <p>Are you sure you want to delete this goal map?</p>
        <div className="flex items-center justify-center">
          <button
            onClick={confirmDelete}
            className="bg-red text-white px-4 py-2 rounded m-2"
          >
            Yes, Delete
          </button>
          <button
            onClick={cancelDelete}
            className="bg-gray-500 text-white px-4 py-2 rounded m-2"
          >
            Cancel
          </button>
        </div>
      </PopupComponent>
    </div>
  );
};

export default GoalMapList;
