import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import checkListItemService from './checkListItemService'
import { extractErrorMessage } from '../../utils'
import { current } from '@reduxjs/toolkit'
import { updateChecklist } from '../checklist/checkListSlice'

const initialState = {
  checkListItems: [],
  filteredItems: [],
  tags: [],
  isLoading: false,
  isFilterApplied: false
}

export const createOrUpdateACheckListItem = createAsyncThunk(
  'checkList_item/create_update',
  async (checkListItemData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListItemService.createOrUpdateACheckListItem(checkListItemData, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const deleteACheckListItem = createAsyncThunk(
  'checkList_item/deleteACheckListItem',
  async (checkListItemData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListItemService.deleteACheckListItem(checkListItemData, token)
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const getItemsByCheckListID = createAsyncThunk(
  'checkList_item/getItemsByCheckListID',
  async (checkListID, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListItemService.getItemsByCheckListID(checkListID, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const getChecklistWithItems = createAsyncThunk(
  'checkList_item/getChecklistWithItems',
  async (checklistsWithItems, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListItemService.getChecklistWithItems(checklistsWithItems, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const getAllTagsForUser = createAsyncThunk(
  'checkList_item/getAllTagsForUser',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListItemService.getAllTagsForUser(token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const getChecklistsByTags = createAsyncThunk(
  'checkList_item/getChecklistsByTags',
  async (tags, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListItemService.getChecklistsByTags(tags, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const moveCheckListItems = createAsyncThunk(
  'checkList_item/moveCheckListItems',
  async (moveData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListItemService.moveCheckListItems(moveData, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const addCommentToCheckListItem = createAsyncThunk(
  'checkList_item/addComment',
  async ({ itemID, comment }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListItemService.addCommentToCheckListItem(itemID, comment, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const getChecklistsByStatus = createAsyncThunk(
  'checkList_item/getChecklistsByStatus',
  async (status, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListItemService.getChecklistsByStatus(status, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const checkListItemsSlice = createSlice({
  name: 'checkListItems',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
    },
    setItems: (state) => {
      state.value += 1
    },
    filterItems: (state, action) => {
      const filtereditems = state.checkListItems.filter(item => item.status === action.payload.status)
      return {
        ...state,
        filteredItems: action.payload.status === 'Reset' ? [...state.checkListItems] : filtereditems,
        isFilterApplied: action.payload.status === 'Reset' ? false : true
      }
    },
    filterItemsByTag: (state, action) => {
      const filteredItems = state.checkListItems.filter(item =>
        item.tags.some(tag => tag.name === action.payload)
      )
      return {
        ...state,
        filteredItems,
        isFilterApplied: true
      }
    },
    filterItemsByTags: (state, action) => {
      const filteredItems = state.checkListItems.filter(item =>
        action.payload.every(tag => item.tags.some(t => t.name === tag.name))
      )
      return {
        ...state,
        filteredItems,
        isFilterApplied: true
      }
    },
    filterItemsByTimeRange: (state, action) => {
      return {
        ...state,
        filteredItems: action.payload,
        isFilterApplied: true
      }
    },
    removeCheckListItemData: (state) => {
      state.checkListItems = []
    },
    addNewChecklistItemLocally: (state, action) => {
      state.checkListItems.push(action.payload);
    },
    clearCheckListItems: (state) => {
      state.checkListItems = []
    },
    updateChecklistItems: (state, action) => {
      state.checkListItems.push(...action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteACheckListItem.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(deleteACheckListItem.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(deleteACheckListItem.fulfilled, (state, action) => {
        state.isLoading = false;
        const itemsToDelete = action.payload.itemsToDelete;
        state.checkListItems = state.checkListItems.filter(
          (item) => !itemsToDelete.some(
            (deletedItem) => deletedItem.itemID === item._id && deletedItem.checkListID === item.checkListID
          )
        );
      })
      .addCase(createOrUpdateACheckListItem.fulfilled, (state, action) => {
        state.checkListItems = state.checkListItems.filter((item) => item._id)
        const itemIndex = state.checkListItems.findIndex((item) => {
          return item._id === action.payload._id
        })
        if (itemIndex !== -1) {
          state.checkListItems[itemIndex] = action.payload
        } else {
          state.checkListItems.push(action.payload)
        }
      })
      .addCase(addCommentToCheckListItem.fulfilled, (state, action) => {
        const itemIndex = state.checkListItems.findIndex((item) => item._id === action.payload._id)
        if (itemIndex !== -1) {
          state.checkListItems[itemIndex] = action.payload
        }
      })
      .addCase(getItemsByCheckListID.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getItemsByCheckListID.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(getItemsByCheckListID.fulfilled, (state, action) => {
        state.isLoading = false
        state.checkListItems = state.checkListItems.filter((item) => item.checkListID !== (action.payload[0].checkListID || undefined))
        state.checkListItems.push(...action.payload)
      })
      .addCase(getChecklistWithItems.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getChecklistWithItems.fulfilled, (state, action) => {
        state.isLoading = false
        console.log("resp:::", action.payload)
        action.payload.forEach(({ checklist, items }) => {
          state.checkListItems = state.checkListItems.filter(item => item.checkListID !== checklist._id)
          state.checkListItems.push(...items)
        //  dispatch(updateChecklist(checklist))
       
        })
      })
      .addCase(getChecklistWithItems.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getAllTagsForUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllTagsForUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.tags = action.payload
      })
      .addCase(getAllTagsForUser.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getChecklistsByTags.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getChecklistsByTags.fulfilled, (state, action) => {
        state.isLoading = false
        state.checkListItems = action.payload.flatMap(({ items }) => items)
      })
      .addCase(getChecklistsByTags.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(moveCheckListItems.fulfilled, (state, action) => {
        state.isLoading = false
        state.checkListItems = state.checkListItems.filter(item => !action.payload.some(movedItem => movedItem._id === item._id))
        state.checkListItems.push(...action.payload) // Add the moved items to the new checklist
      })
      .addCase(getChecklistsByStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getChecklistsByStatus.fulfilled, (state, action) => {
        state.isLoading = false
        state.checkListItems = action.payload.flatMap(({ items }) => items)
      })
      .addCase(getChecklistsByStatus.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { addNewChecklistItemLocally, filterItems, removeCheckListItemData, filterItemsByTag, filterItemsByTags, filterItemsByTimeRange, clearCheckListItems, updateChecklistItems } = checkListItemsSlice.actions
export default checkListItemsSlice.reducer
