import React from "react";

const unitOptions = [
  "kg",
  "g",
  "lb",
  "oz",
  "liters",
  "ml",
  "units",
  "packets",
  "bottles",
  "hours",
  "minutes",
  "seconds",
  "days",
  "weeks",
  "months",
  "years",
];

function UnitType({ unitType, onUnitTypeChange }) {
  return (
    <div>
      <label>Unit :</label>
      <select
        value={unitType}
        onChange={onUnitTypeChange}
        className="border p-2"
      >
        {unitOptions.map((unit, index) => (
          <option key={index} value={unit}>
            {unit}
          </option>
        ))}
      </select>
    </div>
  );
}

export default UnitType;
