import React from "react";

function Counter({ label = "Count", count, onCountChange }) {
  return (
    <div>
      <label>{label + ":"}</label>
      <input
        type="number"
        value={count}
        onChange={onCountChange}
        className="border p-2"
      />
    </div>
  );
}

export default Counter;
