import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/checklist'



// Create new checklistitem
const createOrUpdateACheckListItem = async (checkListItemData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.put(API_URL + "/item", checkListItemData, config)

  return response.data
}

// delete a checklistitem
const deleteACheckListItem = async (checkListItemData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL + "/items/delete", checkListItemData, config);
  return response.data;
}

// Get user tickets
const getItemsByCheckListID = async (checkListID,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL+'/items/'+checkListID, config)

  return response.data
}

// Get checklist with items
const getChecklistWithItems = async (checklistsWithItems, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL + '/items', checklistsWithItems, config)

  return response.data
}

const getAllTagsForUser = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + '/items/tags', config)
  return response.data
}

const getChecklistsByTags = async (tags, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL + '/items/tags', { tags }, config)
  return response.data
}

const getChecklistsByStatus = async (status, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL + '/items/status', { status }, config)
  return response.data
}

const moveCheckListItem = async (moveData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL + "/item/move", moveData, config)

  return response.data
}

const moveCheckListItems = async (moveData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL + "/items/move", moveData, config)

  return response.data
}

const addCommentToCheckListItem = async (itemID, comment, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(`${API_URL}/item/${itemID}/comment`, comment, config)
  return response.data
}

const checkListItemService = {
  createOrUpdateACheckListItem,
  deleteACheckListItem,
  getItemsByCheckListID,
  getChecklistWithItems,
  getAllTagsForUser,
  getChecklistsByTags,
  getChecklistsByStatus,
  moveCheckListItem,
  moveCheckListItems,
  addCommentToCheckListItem
}

export default checkListItemService
