import { createSlice, createAsyncThunk,current } from '@reduxjs/toolkit'
import checkListService from './checkListService'
// NOTE: use a extractErrorMessage function to save some repetition
import { extractErrorMessage } from '../../utils'
import {  getItemsByCheckListID } from '../checklistItem/checkListItemSlice'
import {getItemsDataFromState} from '../checklistItem/checkListItemSlice'


// NOTE: no need for isLoading, isSuccess, isError or message as we can leverage
// our AsyncThunkAction and get Promise reolved or rejected messages at
// component level
const initialState = {
  checkLists: [],
  isLoading: false,
  isError:false



}




// Create new checklist
export const createANewCheckList = createAsyncThunk(
  'checkLists/createANewCheckList',
  async (checkListData, thunkAPI) => {

    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListService.createANewCheckList(checkListData, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)



// delete a checklist
export const deleteACheckList = createAsyncThunk(
  'checkLists/deleteACheckList',
  async (checkListData, thunkAPI) => {

    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListService.deleteACheckList(checkListData, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)



// Get user checklists
export const getCheckLists = createAsyncThunk(
  'checkLists/getCheckLists',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const checklistsResponse = await checkListService.getCheckLists(token, userId);

      console.log("get checklist service called");

      for (let i = 0; i < 3; i++) {
        if (i < checklistsResponse.length) {
          // Dispatch actions for items if needed
          thunkAPI.dispatch(getItemsByCheckListID(checklistsResponse[i]._id));
        }
      }

      return checklistsResponse;
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);


// Get user checklists
export const getSearchCheckListAndItem = createAsyncThunk(
  'checkLists/getSearchCheckListAndItem',
  async (searchText, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const checklistsResponse= await checkListService.getSearchChecklistAndItem(token,searchText)

      
       console.log("get search checklist service called")

       for (let i = 0; i < checklistsResponse.length; i++) {
        //if (i < checklistsResponse.length) {
          // Dispatch actions for items if needed
          thunkAPI.dispatch(getItemsByCheckListID(checklistsResponse[i]._id));
        //}
      }

      // for (let i = 0; i < 3; i++) {
      //   if (i < checklistsResponse.length) {
      //     // Dispatch actions for items if needed
      //     thunkAPI.dispatch(getItemsByCheckListID(checklistsResponse[i]._id));
      //   }
      // }
    
       
       return checklistsResponse
    } catch (error) {
      
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

// Get user info
export const getUserInfo = createAsyncThunk(
  'checkLists/getUserInfo',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await checkListService.getUserInfo(userId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

// Get checklist by ID
export const getCheckListById = createAsyncThunk(
  'checkLists/getCheckListById',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await checkListService.getCheckListById(id, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const checkListSlice = createSlice({
  name: 'checkLists',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false

    },
    addNewChecklistLocally: (state,action) =>{
      
      state.checkLists.unshift(action.payload)
      
    },
    removeCheckListData:(state)=>{
      state.checkLists=[]
    },
    updateChecklist: (state, action) => {
      const index = state.checkLists.findIndex(checklist => checklist._id === action.payload._id)
      if (index !== -1) {
        state.checkLists[index] = action.payload
      } else {
        state.checkLists.push(action.payload)
      }
    },
    clearCheckLists: (state) => {
      state.checkLists = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCheckLists.pending, (state) => {
        // NOTE: clear single ticket on tickets page, this replaces need for
        // loading state on individual ticket
        state.isError=false
        state.checkList = null
        state.isLoading=true
        
      })
      .addCase(getCheckLists.rejected, (state) => {
        // NOTE: clear single ticket on tickets page, this replaces need for
        // loading state on individual ticket
        state.isError=true
        state.isLoading=false
        
      })
      .addCase(getCheckLists.fulfilled, (state, action) => {
        state.isError=false
        state.checkLists = action.payload
        state.isLoading=false

        // let checklistItems_unsortedarr=state.checkListItems
        // let sorted_checklistItems=checklistItems_unsortedarr.forEach((item) => {
        //   item.items.sort((a, b) => {
        //     const statusOrder = { pending: 1, completed: 2 };
        //     return statusOrder[a.status] - statusOrder[b.status];
        //   });
        // });

        


      })
      .addCase(getSearchCheckListAndItem.pending, (state) => {
        // NOTE: clear single ticket on tickets page, this replaces need for
        // loading state on individual ticket
        state.isError=false
        state.checkList = null
        state.isLoading=true
        
      })
      .addCase(getSearchCheckListAndItem.rejected, (state) => {
        // NOTE: clear single ticket on tickets page, this replaces need for
        // loading state on individual ticket
        state.isError=true
        state.isLoading=false
        
      })
      .addCase(getSearchCheckListAndItem.fulfilled, (state, action) => {
        state.isError=false
        state.checkLists = action.payload
        state.isLoading=false

        // let checklistItems_unsortedarr=state.checkListItems
        // let sorted_checklistItems=checklistItems_unsortedarr.forEach((item) => {
        //   item.items.sort((a, b) => {
        //     const statusOrder = { pending: 1, completed: 2 };
        //     return statusOrder[a.status] - statusOrder[b.status];
        //   });
        // });

        


      })
      .addCase(createANewCheckList.pending, (state) => {
        console.log('pending')
        // NOTE: clear single ticket on tickets page, this replaces need for
        // loading state on individual ticket
        
      })
      .addCase(createANewCheckList.rejected, (state,action) => {
        console.log('rejected')
        state.checkLists=state.checkLists.filter((item)=>item.name!=="")
        // NOTE: clear single ticket on tickets page, this replaces need for
        // loading state on individual ticket
        
      })
      .addCase(createANewCheckList.fulfilled, (state, action) => {
       
        // state.checkList = action.payload
        state.checkLists=state.checkLists.filter((item)=>item._id)
        console.log(current(state))
        const itemIndex = state.checkLists.findIndex((item)=>{
            
          return item._id === action.payload._id
          });
          
          if (itemIndex !== -1) {
           // Replace the object at the found index with the updated item
           state.checkLists[itemIndex] = action.payload;
         //  console.log(`Updated item with _id ${itemIdToUpdate}`);
         } else {
           state.checkLists.push(action.payload);
         //  console.log(`Item with _id ${itemIdToUpdate} not found.`);
         }
      //  state.checkLists.find(item => item._id === action.payload._id) || state.checkLists.push(action.payload)
      })
      .addCase(deleteACheckList.pending, (state) => {
        // NOTE: clear single ticket on tickets page, this replaces need for
        // loading state on individual ticket

      })
      .addCase(deleteACheckList.fulfilled, (state, action) => {
        // state.checkList = action.payload

        state.checkLists = state.checkLists.filter(item => item._id !== action.payload._id)
        //  arrayOfObjects = arrayOfObjects.filter(item => item.id !== idToRemove);
      })
      .addCase(getUserInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        // Handle the user info response
        console.log('User info:', action.payload);
      })
      .addCase(getUserInfo.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getCheckListById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCheckListById.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.checkLists.findIndex(checklist => checklist._id === action.payload._id);
        if (index !== -1) {
          state.checkLists[index] = action.payload;
        } else {
          state.checkLists.push(action.payload);
        }
      })
      .addCase(getCheckListById.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
      
  },
})

export const { addNewChecklistLocally,removeCheckListData, updateChecklist, clearCheckLists } = checkListSlice.actions;
export default checkListSlice.reducer
