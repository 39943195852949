import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  addEdge,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  Position,
} from "reactflow";
import "reactflow/dist/style.css";
import { getCheckLists } from "../features/checklist/checkListSlice";
import {
  fetchGoalMaps,
  createGoalMap,
  updateGoalMap,
} from "../features/goalmap/goalMapSlice";
import CustomeNode from "../components/CustomeNode"; // Import the custom node component
import "./GoalMapPage.css"; // Import the custom CSS file
import { FaPencilAlt } from "react-icons/fa"; // Import pencil icon
import Spinner from "../components/Spinner";

const customStyles = {
  handle: {
    width: "12px",
    height: "12px",
    backgroundColor: "#555",
  },
};

const GoalMapPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams(); // Get goal map ID from URL
  const navigate = useNavigate(); // added
  const { checkLists, isLoading } = useSelector((state) => state.checkLists);
  const { goalMaps } = useSelector((state) => state.goalMaps);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  // Add mapName state variable
  const [mapName, setMapName] = useState("");
  const [isEditing, setIsEditing] = useState(false); // Add state for editing mode

  const nodeTypes = useMemo(() => ({ custom: CustomeNode }), []); // Memoize the custom node type

  // Fetch checklist items
  useEffect(() => {
    dispatch(getCheckLists());
    //  dispatch(fetchGoalMaps());
  }, [dispatch]);

  const updatedChecklists = useCallback(
    (id) => {
      console.log("id", id);
      const arr = checkLists.filter((checklist) => checklist._id === id);
      if (arr.length === 0) {
        return null;
      }
      return (
        arr[0].name +
        "(" +
        arr[0].checkListItemCount.completed +
        "/" +
        arr[0].checkListItemCount.total +
        ")"
      );
    },
    [checkLists]
  );

  // Load goal map only once
  useEffect(() => {
    if (checkLists.length === 0) return;
    if (id && goalMaps.length > 0) {
      const existingGoalMap = goalMaps.find((goal) => goal._id === id);
      if (existingGoalMap) {
        const updatedNodes = existingGoalMap.nodes.map((node) => ({
          ...node,
          // Change type to use the custom node style if desired:
          type: "custom",
          data: {
            ...node.data,
            label: updatedChecklists(node.id) || node.data.label,
          },
          // Remove forward handle for "Achieve Goal" node
          // sourcePosition: node.id === "goal-node" ? null : "right",
        }));
        setNodes(updatedNodes);
        setEdges(existingGoalMap.edges || []);
        setMapName(existingGoalMap.name);
      }
    } else if (!id) {
      // For new goal maps: create default empty flow with custom type
      const initialNodes = [
        {
          id: "goal-node",
          type: "custom", // changed from "output" to "custom"
          data: { label: "Achieve Goal" },
          position: { x: 400, y: 300 },
          draggable: false,
          // Remove forward handle for "Achieve Goal" node
          sourcePosition: Position.Top,
        },
      ];
      setNodes(initialNodes);
    }
  }, [checkLists, id, goalMaps, setNodes, setEdges, updatedChecklists]);

  useEffect(() => {
    const calculateOverallProgress = (nodes) => {
      let totalCompleted = 0;
      let totalItems = 0;

      nodes.forEach((node) => {
        const regex = /(.*)\((\d+)\/(\d+)\)$/;
        const match = regex.exec(node.data.label);
        if (match) {
          totalCompleted += Number(match[2]);
          totalItems += Number(match[3]);
        }
      });

      return {
        percent:
          totalItems > 0 ? Math.round((totalCompleted / totalItems) * 100) : 0,
        completed: totalCompleted,
        total: totalItems,
      };
    };

    if (nodes.length > 0) {
      const { percent, completed, total } = calculateOverallProgress(nodes);
      setNodes((nds) =>
        nds.map((node) =>
          node.id === "goal-node"
            ? {
                ...node,
                data: {
                  ...node.data,
                  label: `Achieve Goal (${percent}% completed) ${completed}/${total}`,
                },
              }
            : node
        )
      );
    }
  }, [nodes]);

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      if (!reactFlowInstance) return;

      const typeData = event.dataTransfer.getData("application/reactflow");
      if (!typeData) return;
      const typeObj = JSON.parse(typeData);

      const position = reactFlowInstance.project({
        x: event.clientX - event.target.getBoundingClientRect().left,
        y: event.clientY - event.target.getBoundingClientRect().top,
      });

      const label = updatedChecklists(typeObj.id) || typeObj.text;

      const newNode = {
        id: typeObj.id,
        type: "custom", // changed from "default" to "custom"
        position,
        data: { label },
        // Remove forward handle for "Achieve Goal" node
        sourcePosition: typeObj.id === "goal-node" ? null : "right",
      };

      setNodes((nds) => [...nds, newNode]);
    },
    [reactFlowInstance, setNodes, updatedChecklists]
  );

  const handleSave = () => {
    const flowData = { name: mapName, nodes, edges };
    if (id) {
      flowData.goalMapId = id;
      dispatch(updateGoalMap({ updatedData: flowData, goalMapId: id }))
        .unwrap()
        .then((updatedItem) => {
          toast.success("Goal Map saved successfully!");
        })
        .catch((error) => {
          toast.error(error.message || "Something went wrong!", {
            autoClose: 2000,
          });
        });
    } else {
      dispatch(createGoalMap(flowData))
        .unwrap()
        .then((updatedItem) => {
          toast.success("Goal Map saved successfully!");
        })
        .catch((error) => {
          toast.error(error.message || "Something went wrong!", {
            autoClose: 2000,
          });
        });
    }
    // Toast success message and navigate back to the list screen
    toast.success("Goal map saved successfully!");
    navigate("/goal-maps");
  };

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData(
      "application/reactflow",
      JSON.stringify(nodeType)
    );
    event.dataTransfer.effectAllowed = "move";
  };

  const onTouchStart = (event, nodeType) => {
    event.preventDefault();
    const touch = event.touches[0];
    event.target.customData = {
      nodeType,
      touchX: touch.clientX,
      touchY: touch.clientY,
    };
  };

  const onTouchMove = (event) => {
    event.preventDefault();
    const touch = event.touches[0];
    const target = event.target;
    const { touchX, touchY } = target.customData;
    const dx = touch.clientX - touchX;
    const dy = touch.clientY - touchY;
    target.style.transform = `translate(${dx}px, ${dy}px)`;
  };

  const onTouchEnd = (event) => {
    event.preventDefault();
    const target = event.target;
    const { nodeType, touchX, touchY } = target.customData;
    const position = reactFlowInstance.project({
      x: touchX,
      y: touchY,
    });
    const label = updatedChecklists(nodeType.id) || nodeType.text;
    const newNode = {
      id: nodeType.id,
      type: "custom",
      position,
      data: { label },
    };
    setNodes((nds) => [...nds, newNode]);
    target.style.transform = "";
  };

  const onNodesDelete = useCallback(
    (deletedNodes) => {
      setNodes((nds) => nds.filter((node) => !deletedNodes.includes(node)));
    },
    [setNodes]
  );

  const onEdgesDelete = useCallback(
    (deletedEdges) => {
      setEdges((eds) => eds.filter((edge) => !deletedEdges.includes(edge)));
    },
    [setEdges]
  );

  const handleNodeDoubleTap = useCallback(
    (event, node) => {
      setNodes((nds) => nds.filter((n) => n.id !== node.id));
    },
    [setNodes]
  );

  const handleEdgeDoubleTap = useCallback(
    (event, edge) => {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    },
    [setEdges]
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      // Handle resize
    });

    if (reactFlowInstance?.wrapper) {
      resizeObserver.observe(reactFlowInstance.wrapper);
    }

    return () => {
      if (reactFlowInstance?.wrapper) {
        resizeObserver.unobserve(reactFlowInstance.wrapper);
      }
      resizeObserver.disconnect();
    };
  }, [reactFlowInstance]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
  };
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col h-screen">
      {/* Header */}
      <div className="flex flex-wrap justify-between items-center p-4 bg-gray-200 border-b">
        <div className="flex items-center">
          {/* <h1 className="text-xl font-semibold mr-2">Goal Map</h1> */}
          <div className="flex font-bold text-gray-800 items-center">
            {isEditing ? (
              <input
                type="text"
                value={mapName}
                onChange={(e) => setMapName(e.target.value)}
                onBlur={handleInputBlur}
                className="p-2 border border-gray-300 rounded mr-2"
                placeholder="Enter map name"
                autoFocus
              />
            ) : (
              <span className="p-2 border border-transparent rounded mr-2">
                {mapName || "Enter map name"}
              </span>
            )}
            <FaPencilAlt
              className="text-gray-600 cursor-pointer"
              onClick={handleEditClick}
            />
          </div>
        </div>
        <button
          className="px-4 py-2 bg-purple-500 text-white rounded shadow hover:opacity-45"
          onClick={handleSave}
        >
          Save Goal Map
        </button>
      </div>

      <div className="flex flex-grow">
        {/* Sidebar for Dragging Nodes */}
        <div className="w-1/4 p-4 bg-gray-100 border-r overflow-y-auto">
          {/* Remove the existing map input box and save button */}
          {/* <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Map Name
            </label>
            <input
              type="text"
              value={mapName}
              onChange={(e) => setMapName(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded"
              placeholder="Enter map name"
            />
            <button
              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-700 w-full"
              onClick={handleSave}
            >
              Save Goal Map
            </button>
          </div> */}
          <h2 className="text-lg font-semibold mb-4 text-gray-800">
            Checklists
          </h2>
          <div className="space-y-2">
            {checkLists.map((checklist, index) => (
              <div
                key={index}
                className="p-2 break-all bg-white rounded shadow cursor-pointer font-normal text-sm text-gray-600"
                draggable
                onDragStart={(event) =>
                  onDragStart(event, {
                    text:
                      checklist.name +
                      "(" +
                      checklist.checkListItemCount.completed +
                      "/" +
                      checklist.checkListItemCount.total +
                      ")",
                    id: checklist._id,
                  })
                }
                onTouchStart={(event) =>
                  onTouchStart(event, {
                    text:
                      checklist.name +
                      "(" +
                      checklist.checkListItemCount.completed +
                      "/" +
                      checklist.checkListItemCount.total +
                      ")",
                    id: checklist._id,
                  })
                }
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                {checklist.name +
                  "(" +
                  checklist.checkListItemCount.completed +
                  "/" +
                  checklist.checkListItemCount.total +
                  ")"}
              </div>
            ))}
          </div>
        </div>

        {/* React Flow Area */}
        <div className="w-3/4 h-full">
          <ReactFlowProvider>
            <div style={{ height: "100%", width: "100%" }}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                onDrop={onDrop}
                onDragOver={onDragOver}
                nodeTypes={nodeTypes} // Add the nodeTypes property
                fitView
                onNodesDelete={onNodesDelete} // Add node delete handler
                onEdgesDelete={onEdgesDelete} // Add edge delete handler
                onNodeDoubleClick={handleNodeDoubleTap}
                onEdgeDoubleClick={handleEdgeDoubleTap}
                panOnDrag // Enable dragging of the main area
                panOnScroll // Enable panning on scroll
                panOnScrollSpeed={0.5} // Adjust the speed of panning on scroll
                zoomOnPinch // Enable zooming on pinch
              >
                <Controls position="top-left" />
                <Background />
              </ReactFlow>
            </div>
          </ReactFlowProvider>
        </div>
      </div>
    </div>
  );
};

export default GoalMapPage;
