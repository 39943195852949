import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FaHourglassHalf,
  FaCheckCircle,
  FaList,
  FaPlusCircle,
  FaSearch,
  FaUserClock,
} from "react-icons/fa";
import {
  AiOutlineSwap,
  AiOutlineDelete,
  AiFillBoxPlot,
  AiOutlineRadarChart,
  AiOutlineHeatMap,
} from "react-icons/ai";
import { MdUpdate } from "react-icons/md";

import EditableTextBox from "../components/CheckListItem";
import CheckList from "./CheckList";
import Spinner from "../components/Spinner";
import FloatingButton from "../components/FloatingButton";
import Chip from "../components/Chip";
import SearchBar from "../components/SearchBar";
import Tag from "../components/Tag";
import PopupComponent from "../components/PopupComponent";

import {
  getCheckLists,
  addNewChecklistLocally,
  removeCheckListData,
  getSearchCheckListAndItem,
  updateChecklist,
  clearCheckLists,
  getCheckListById,
} from "../features/checklist/checkListSlice";
import {
  removeCheckListItemData,
  filterItems,
  filterItemsByTags,
  filterItemsByTimeRange,
  getAllTagsForUser,
  getChecklistsByTags,
  clearCheckListItems,
  moveCheckListItems,
  getItemsByCheckListID,
  deleteACheckListItem,
  updateChecklistItems, // Import the delete action
} from "../features/checklistItem/checkListItemSlice";
import { setUser, logout } from "../features/auth/authSlice";
import TagBar from "../components/TagBar";
import { getCategories } from "../features/category/categorySlice";
import {
  createFocusedWork,
  updateFocusedWorkItems, // Import the update action
} from "../features/dashboard/dashboardSlice";

function CheckLists() {
  const { userId, id } = useParams();
  const { checkLists, checkList, isLoading, error } = useSelector(
    (state) => state.checkLists
  );
  const { checkListItems, tags } = useSelector((state) => state.checkListItems);
  const { categories } = useSelector((state) => state.categories);

  const dispatch = useDispatch();
  const [createNewChecklist, setcreateNewChecklist] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();
  const typingTimeoutRef = useRef(null);
  const searchBarRef = useRef(null);
  const location = useLocation();

  const [search, setSearch] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [isMovePopupOpen, setIsMovePopupOpen] = useState(false);
  const [selectedCheckListID, setSelectedCheckListID] = useState("");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false); // State for delete popup
  const [isCategoryPopupOpen, setIsCategoryPopupOpen] = useState(false); // State for category popup

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const handleClickOutside = (event) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      setShowSearchBar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onTagClickHandler = (tag) => {
    dispatch(clearCheckLists);
    dispatch(clearCheckListItems);

    dispatch(getChecklistsByTags([tag.name]));
  };

  const onSearchHandler = (search) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setShowSearchBar(false);
    }, 3000);

    dispatch(getSearchCheckListAndItem(search))
      .unwrap()
      .then((user) => {})
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
  };

  useEffect(() => {
    if (location.state?.fromGoalMaps) {
      console.log("fromGoalMaps", location.state);
      if (id) {
        dispatch(clearCheckListItems());
        dispatch(clearCheckLists());
        dispatch(getCheckListById(id));
        dispatch(getItemsByCheckListID(id));
      }
    } else if (location.state?.fromDashboard) {
      const { checklistWithItems } = location.state;

      if (location.state?.focusedWork) {
        //focused work here
        console.log("focused work here", checklistWithItems);
        checklistWithItems.focusedwork.forEach(({ checkListID, items }) => {
          dispatch(updateChecklist(checkListID));
          dispatch(updateChecklistItems(items)); // Dispatch for items
          // Handle the checklistWithItems data here
          // For example, you can set it to the state or dispatch an action to store it
        });
      } else {
        checklistWithItems.forEach(({ checklist, items }) => {
          console.log("dashboard work here", checklistWithItems);
          dispatch(updateChecklist(checklist));

          // Handle the checklistWithItems data here
          // For example, you can set it to the state or dispatch an action to store it
        });
      }
    } else {
      dispatch(getCheckLists(userId))
        .unwrap()
        .then(() => {})
        .catch((err) => {
          if (err.httpStatusCode === 401) {
            dispatch(setUser(null));
            dispatch(removeCheckListData());
            dispatch(removeCheckListItemData());
            dispatch(logout());
            navigate("/login");
          } else {
            toast.error(err);
          }
        });
    }
    dispatch(getAllTagsForUser());
    dispatch(getCategories());
  }, [dispatch, userId, navigate, location.state, id]);

  // useEffect(() => {
  //   if (id) {
  //     dispatch(clearCheckListItems());
  //     dispatch(clearCheckLists());
  //     dispatch(getCheckListById(id));
  //   }
  // }, [dispatch, id]);

  const handleCheckListAdd = () => {
    setIsCategoryPopupOpen(true);
  };

  const handleFilterForLocalItems = (tag) => {
    dispatch(filterItems({ status: tag.name }));
  };

  const handleAddCheckListBox = () => {
    setcreateNewChecklist(true);
  };

  const handleTagClick = (tag) => {
    const updatedTags = selectedTags.map((t) => t.name).includes(tag.name)
      ? selectedTags.filter((t) => t.name !== tag.name)
      : [...selectedTags, tag];
    setSelectedTags(updatedTags);
    // console.log("selectedTags", updatedTags);
    if (updatedTags.length === 0) {
      dispatch(getCheckLists(userId));
    } else {
      dispatch(filterItemsByTags(updatedTags));
      dispatch(getChecklistsByTags(updatedTags.map((tag) => tag.name)))
        .unwrap()
        .then((checklistWithItems) => {
          dispatch(clearCheckLists());
          checklistWithItems.forEach(({ checklist, items }) => {
            dispatch(updateChecklist(checklist));
          });
        })
        .catch((err) => {
          if (err.httpStatusCode === 401) {
            dispatch(setUser(null));
            dispatch(removeCheckListData());
            dispatch(removeCheckListItemData());
            dispatch(logout());
            navigate("/login");
          } else if (err.httpStatusCode === 400) {
            dispatch(getCheckLists(userId));
          } else {
            toast.error(err.message);
          }
        });
    }
  };

  const handlePendingItemsClick = () => {
    const now = new Date();
    const within12Hours = checkListItems.filter((item) => {
      const endDate = new Date(item.endDate);
      return (
        item.status === "pending" &&
        item.startDate &&
        item.endDate &&
        endDate - now <= 12 * 60 * 60 * 1000
      );
    });
    dispatch(filterItemsByTimeRange(within12Hours));
  };

  const handleSelectItem = (itemId) => {
    console.log("handleSelectItem called with itemId:", itemId);
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((id) => id !== itemId)
        : [...prevSelectedItems, itemId]
    );
  };

  const handleMoveClick = () => {
    setIsMovePopupOpen(true);
  };

  const handleMoveConfirm = () => {
    dispatch(
      moveCheckListItems({
        itemIDs: selectedItems,
        newCheckListID: selectedCheckListID,
      })
    )
      .unwrap()
      .then((movedItems) => {
        toast.success("Items moved");
        setSelectedItems([]); // Clear selected items
        dispatch(getItemsByCheckListID(selectedCheckListID)); // Fetch items for the new checklist
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
    setIsMovePopupOpen(false);
  };

  const handleMoveCancel = () => {
    setIsMovePopupOpen(false);
  };

  const handleDeleteClick = () => {
    setIsDeletePopupOpen(true);
  };

  const handleDeleteConfirm = () => {
    const itemsToDelete = selectedItems.map((itemID) => ({
      itemID,
      checkListID: checkListItems.find((item) => item._id === itemID)
        .checkListID,
    }));

    dispatch(deleteACheckListItem(itemsToDelete))
      .unwrap()
      .then(() => {
        toast.success("Items deleted");
        setSelectedItems([]); // Clear selected items
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
    setIsDeletePopupOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeletePopupOpen(false);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setIsCategoryPopupOpen(false);
    dispatch(addNewChecklistLocally({ name: "", status: "pending", category }));
  };

  const handleCreateFocusedWork = () => {
    const focusedWorkData = selectedItems.reduce((acc, itemId) => {
      const item = checkListItems.find((item) => item._id === itemId);
      const existingCheckList = acc.find(
        (fw) => fw.checkListID === item.checkListID
      );
      if (existingCheckList) {
        existingCheckList.items.push({ _id: item._id });
      } else {
        acc.push({
          checkListID: item.checkListID,
          items: [{ _id: item._id }],
        });
      }
      return acc;
    }, []);

    dispatch(createFocusedWork({ focusedwork: focusedWorkData }))
      .unwrap()
      .then(() => {
        toast.success(
          <div>
            Focused work created
            <button
              onClick={() => navigate("/")}
              className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
            >
              Go to Dashboard
            </button>
          </div>
        );
        setSelectedItems([]); // Clear selected items
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 5000,
        });
      });
  };

  const handleUpdateFocusedWork = () => {
    const focusedWorkData = selectedItems.reduce((acc, itemId) => {
      const item = checkListItems.find((item) => item._id === itemId);
      const existingCheckList = acc.find(
        (fw) => fw.checkListID === item.checkListID
      );
      if (existingCheckList) {
        existingCheckList.items.push({ _id: item._id });
      } else {
        acc.push({
          checkListID: item.checkListID,
          items: [{ _id: item._id }],
        });
      }
      return acc;
    }, []);

    dispatch(updateFocusedWorkItems({ focusedwork: focusedWorkData }))
      .unwrap()
      .then(() => {
        toast.success(
          <div>
            Focused work updated
            <button
              onClick={() => navigate("/")}
              className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
            >
              Go to Dashboard
            </button>
          </div>
        );
        setSelectedItems([]); // Clear selected items
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 5000,
        });
      });
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div id="checklist-page">
      <>
        {checkLists.length === 0 ? (
          <h3>No Checklists created yet...</h3>
        ) : (
          <>
            <div className="sticky top-0 bg-white shadow-md rounded-lg p-4 flex flex-wrap items-center justify-between gap-4 z-20">
              {/* Search Input */}
              <div className="flex items-center w-full md:w-auto">
                <input
                  type="text"
                  placeholder="Search..."
                  className="border border-gray-300 rounded-lg px-4 py-2 w-full md:w-64 focus:ring focus:ring-blue-300 focus:outline-none"
                  value={search}
                  onChange={onChange}
                />
                <button
                  className="ml-2 bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                  onClick={() => onSearchHandler(search)}
                >
                  Search
                </button>
                {selectedItems.length > 0 && (
                  <>
                    <button
                      className="ml-2 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
                      onClick={handleMoveClick}
                    >
                      <AiOutlineSwap />
                    </button>
                    <button
                      className="ml-2 bg-red text-white px-4 py-2 rounded-lg hover:opacity-80"
                      onClick={handleDeleteClick}
                    >
                      <AiOutlineDelete />
                    </button>
                    <button
                      className="ml-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                      onClick={handleCreateFocusedWork}
                    >
                      <FaUserClock />
                    </button>
                    <button
                      className="ml-2 bg-yellow-600 text-white px-4 py-2 rounded-lg hover:bg-yellow-700"
                      onClick={handleUpdateFocusedWork}
                    >
                      <MdUpdate />
                    </button>
                  </>
                )}
              </div>

              {/* Tags Section */}
              <div className="flex flex-wrap items-center gap-2">
                {tags.map((tag) => (
                  <Tag
                    key={tag._id}
                    tag={{ name: tag.name, color: "#fef4f6" }}
                    onClick={handleTagClick}
                    isSelected={selectedTags
                      .map((item) => item.name)
                      .includes(tag.name)}
                  />
                ))}
              </div>

              {/* Add Checklist Button */}
              <button
                className="bg-emerald-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
                onClick={handleCheckListAdd}
              >
                + Add Checklist
              </button>
            </div>
            <div className="flex flex-wrap gap-2 items-center justify-center  py-2 px-2 bg-white">
              {/* {!showSearchBar && (
                <FaSearch
                  className="cursor-pointer text-gray-600"
                  onClick={() => setShowSearchBar(true)}
                />
              )} */}
              {/* <div
                ref={searchBarRef}
                className={`origin-right transform transition-transform duration-200 ease-out ${
                  showSearchBar ? "scale-x-100" : "scale-x-0"
                }`}
              >
                {showSearchBar && <SearchBar onClick={onSearchHandler} />}
              </div> */}
              {/* <Tag
                tag={{ name: "pending", color: "gray" }}
                onClick={handleFilterForLocalItems}
                icon={<FaHourglassHalf />}
              />
              <Tag
                tag={{ name: "completed", color: "gray" }}
                onClick={handleFilterForLocalItems}
                icon={<FaCheckCircle />}
              />
              <Tag
                tag={{ name: "Reset", color: "orange" }}
                onClick={handleFilterForLocalItems}
                icon={<FaList />}
              />
              <Tag
                tag={{ name: "Add Checklist", color: "gray" }}
                onClick={handleCheckListAdd}
                icon={<FaPlusCircle />}
              /> */}
              {/* <Tag
                tag={{ name: "Pending Items (12h)", color: "red" }}
                onClick={handlePendingItemsClick}
                icon={<FaHourglassHalf />}
              /> */}
              {/* {tags.map((tag) => (
                //  console.log("tag", selectedTags),
                <Tag
                  key={tag}
                  tag={{ name: tag, color: "gray" }}
                  onClick={handleTagClick}
                  isSelected={selectedTags
                    .map((item) => item.name)
                    .includes(tag)}
                />
              ))} */}
            </div>

            <div>
              {checkLists.map((checkList) => (
                <CheckList
                  key={checkList._id}
                  checklist={checkList}
                  enableAddButton={true}
                  onSelectItem={handleSelectItem}
                  selectedItems={selectedItems}
                />
              ))}
            </div>
          </>
        )}
      </>
      <PopupComponent isOpen={isMovePopupOpen} onClose={handleMoveCancel}>
        <h2 className="flex items-center justify-center text-xl font-bold">
          Move Checklist Items
        </h2>
        <p>Select the new checklist:</p>
        <select
          value={selectedCheckListID}
          onChange={(e) => setSelectedCheckListID(e.target.value)}
          className="border p-2 w-full"
        >
          <option value="">Select Checklist</option>
          {checkLists.map((checklist) => (
            <option key={checklist._id} value={checklist._id}>
              {checklist.name}
            </option>
          ))}
        </select>
        <div className="flex items-center justify-center">
          <button
            onClick={handleMoveConfirm}
            className="bg-blue-500 text-white px-4 py-2 rounded m-2"
          >
            Move
          </button>
          <button
            onClick={handleMoveCancel}
            className="bg-gray-500 text-white px-4 py-2 rounded m-2"
          >
            Cancel
          </button>
        </div>
      </PopupComponent>
      {/* <PopupComponent isOpen={isDeletePopupOpen} onClose={handleDeleteCancel}>
        <h2 className="flex items-center justify-center text-xl font-bold">
          Delete Checklist Items
        </h2>
        <p>Are you sure you want to delete the selected items?</p>
        <div className="flex items-center justify-center">
          <button
            onClick={handleDeleteConfirm}
            className="bg-red-500 text-white px-4 py-2 rounded m-2"
          >
            Delete
          </button>
          <button
            onClick={handleDeleteCancel}
            className="bg-gray-500 text-white px-4 py-2 rounded m-2"
          >
            Cancel
          </button>
        </div>
      </PopupComponent> */}

      <PopupComponent isOpen={isDeletePopupOpen} onClose={handleDeleteCancel}>
        <h2 className=" flex items-center justify-center text-xl font-bold text-gray-500">
          Confirm Deletion
        </h2>
        <p className="text-gray-500">
          Are you sure you want to delete the selected items?
        </p>
        <div className="flex items-center justify-center">
          <button
            onClick={handleDeleteConfirm}
            className="bg-red text-white px-4 py-2 rounded m-2"
          >
            Yes, Delete
          </button>
          <button
            onClick={handleDeleteCancel}
            className="bg-gray-500 text-white px-4 py-2 rounded m-2"
          >
            Cancel
          </button>
        </div>
      </PopupComponent>
      <PopupComponent
        isOpen={isCategoryPopupOpen}
        onClose={() => setIsCategoryPopupOpen(false)}
      >
        <h2 className="flex items-center justify-center text-xl font-bold">
          Select Checklist Category
        </h2>
        <div className="flex flex-wrap items-center justify-center gap-2">
          {categories.map((category) => (
            <button
              key={category._id}
              onClick={() => handleCategorySelect(category)}
              className="bg-blue-500 text-white px-4 py-2 rounded m-2"
            >
              {category.name}
            </button>
          ))}
        </div>
      </PopupComponent>
    </div>
  );
}

export default CheckLists;
